import styled from '@emotion/styled'
import type { FC } from 'react'
import { useMemo } from 'react'

import ColumnTrack from '@segment/matcha/components/ColumnTrack'

import ButtonText from '@segment/matcha/components/ButtonText'

import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import { useGatedContent } from 'contexts/gatedContent'

import type { Lesson } from '@segment/api/routes/growthMasters/queries'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import Card from '../../components/Card'

interface WhatWillYouGetProps {
  lessons: Lesson[]
}
const WhatWillYouGet: FC<WhatWillYouGetProps> = ({ lessons }) => {
  const pageConfig = usePageConfig()
  const { showContent } = useGatedContent()

  const copy = useMemo(() => {
    const copy = {
      heading: ``,
      description: ``,
      buttonText: ``,
      buttonLink: `/growth-masters/speakers/`,
    }

    if (showContent) {
      copy.heading = `The Curriculum`
      copy.description = `The on-demand lessons will teach you both the fundamentals and cutting-edge tactics of product management and growth.`
      copy.buttonText = `Explore all sessions`
      copy.buttonLink = `/growth-masters/curriculum/`
    } else {
      copy.heading = `What you’ll get from Growth Masters`
      copy.description = `Growth Masters is an on-demand video collection that will teach you how to build better products for your customers and grow your business. Each video lesson is led by an industry thought leader and delivers actionable recommendations on topics like user research, experimentation, onboarding, and more. Sign up to unlock access to on-demand lessons and live events.`
      copy.buttonText = `About our speakers`
    }

    return copy
  }, [showContent])

  return (
    <SectionBase variant="centered">
      <Section.Copy>
        <CopyTextBase centered={true}>
          <StyledCopyTextHeading tag="h2" variant="h2">
            {copy.heading}
          </StyledCopyTextHeading>
          <CopyText.Description>{copy.description}</CopyText.Description>
        </CopyTextBase>
      </Section.Copy>
      <Section.Contents>
        <ColumnTrack
          overrides={{
            sm: 1,
            lg: 3,
          }}
        >
          {lessons.map((card, key) => (
            <Card
              slug={card.slug}
              title={card.title}
              speaker={card.speaker}
              badges={card.badges}
              key={card.title + key}
              items={card.resources.map((r) => r.title)}
              gated={!showContent}
            />
          ))}
        </ColumnTrack>
        <ButtonWrapper>
          <ButtonText href={getAbsoluteUrl(copy.buttonLink, pageConfig)} iconEnd={ChevronRightIcon}>
            {copy.buttonText}
          </ButtonText>
        </ButtonWrapper>
      </Section.Contents>
    </SectionBase>
  )
}

const StyledCopyTextHeading = styled(CopyText.Heading)`
  margin: 0 auto;
`

const ButtonWrapper = styled.div`
  padding-top: 56px;
  text-align: center;
`

export default WhatWillYouGet
