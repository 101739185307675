import type { FC } from 'react'
import { useMemo } from 'react'

import styled from '@emotion/styled'

import ButtonText from '@segment/matcha/components/ButtonText'
import { ChevronRightIcon } from '@segment/matcha/components/Icons/Common'
import Badge from '@segment/matcha/components/Badge'

import Typography from '@segment/matcha/components/Typography'

import CardBase, * as CardRecipe from '@segment/matcha/recipes/Card'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

export interface CardProps {
  badges: string[]
  title: string
  items?: string[]
  description?: string
  speaker: {
    name: string
    title: string
    company: string
    headshotUrl: string
  }
  slug: string
  gated: boolean
}

const Card: FC<CardProps> = ({ badges, title, items, speaker, slug, description, gated }) => {
  const pageConfig = usePageConfig()

  const copy = useMemo(() => {
    const copy = {
      buttonText: ``,
      buttonLink: ``,
    }

    if (gated) {
      copy.buttonText = `Sign up to watch`
      copy.buttonLink = `/growth-masters/sign-up/?page=${slug}`
    } else {
      copy.buttonText = `Watch the lesson`
      copy.buttonLink = `/growth-masters/${slug}/`
    }

    return copy
  }, [slug, gated])

  return (
    <CardBase href={getAbsoluteUrl(copy.buttonLink, pageConfig)}>
      <StyledCardBody>
        <CardRecipe.Accessories>
          <CardRecipe.AccessoriesRow>
            {badges?.map((topics, key) => (
              <Badge key={topics + key} variant="outlined">
                {topics}
              </Badge>
            ))}
          </CardRecipe.AccessoriesRow>
        </CardRecipe.Accessories>
        <CardRecipe.Copy>
          <CardRecipe.Headline tag="h3">{title}</CardRecipe.Headline>
          {items ? (
            <StyledList tag="ul">
              {items.map((item, key) => (
                <StyledItem key={item + key}>{item}</StyledItem>
              ))}
            </StyledList>
          ) : null}
          {description ? <CardRecipe.Description>{description}</CardRecipe.Description> : null}
        </CardRecipe.Copy>
        <CardRecipe.Accessories>
          <CardRecipe.AccessoriesRow>
            <CardRecipe.Author alt={speaker.name} src={speaker.headshotUrl} />
            <WrapperTitle>
              <StyledTypography variant="textTiny" tag="div">
                {speaker.name}
              </StyledTypography>
              <StyledTypography variant="textTiny" tag="div">
                {speaker.title}, {speaker.company}
              </StyledTypography>
            </WrapperTitle>
          </CardRecipe.AccessoriesRow>
        </CardRecipe.Accessories>
        <CardRecipe.Action alignment="left">
          <ButtonText iconEnd={ChevronRightIcon}>{copy.buttonText}</ButtonText>
        </CardRecipe.Action>
      </StyledCardBody>
    </CardBase>
  )
}

const StyledCardBody = styled(CardRecipe.Body)`
  align-items: flex-start;
  grid-template-rows: min-content min-content 1fr;
`

const StyledList = styled(CardRecipe.Description)`
  padding-left: 20px;
`

const WrapperTitle = styled.div``
const StyledItem = styled.li``

const StyledTypography = styled(Typography)`
  margin: 0;
`

export default Card
