import styled from '@emotion/styled'
import type { FC } from 'react'

import ColumnTrack from '@segment/matcha/components/ColumnTrack'

import { ChatIcon, LearningIcon, DocumentIcon, UsabilityIcon } from '@segment/matcha/components/Icons/Brandbook'

import FeatureBoxBase, * as FeatureBox from '@segment/matcha/components/FeatureBox'

import IconWrapper from '@segment/matcha/components/IconWrapper'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

const copy = {
  heading: `Plus live events and content to solidify your learnings`,
  featureBoxes: [
    {
      icon: <ChatIcon />,
      heading: `Exclusive AMAs`,
      description: `Get specific questions answered during exclusive “ask-me-anything” sessions with featured experts.`,
    },
    {
      icon: <LearningIcon />,
      heading: `Fresh content`,
      description: `New lessons, cheat sheets, and AMAs are added all the time to keep your PM skills sharp.`,
    },
    {
      icon: <DocumentIcon />,
      heading: `Cheat sheets`,
      description: `Each video lesson is paired with an in-depth, downloadable cheat sheet on the topic covered.`,
    },
    {
      icon: <UsabilityIcon />,
      heading: `Community gatherings`,
      description: `Build your network with live events for PMs in the program, hosted at Segment’s San Francisco office.`,
    },
  ],
}

const PlusLiveEvents: FC = () => {
  return (
    <SectionBase variant="centered">
      <Section.Copy>
        <CopyTextBase centered={true}>
          <StyledCopyTextHeading tag="h3" variant="h2">
            {copy.heading}
          </StyledCopyTextHeading>
        </CopyTextBase>
      </Section.Copy>
      <Section.Contents>
        <ColumnTrack
          overrides={{
            sm: 1,
            md: 2,
            xl: 4,
          }}
        >
          {copy.featureBoxes.map((box) => (
            <FeatureBoxBase alignment="left" headerPosition="top" key={box.heading}>
              <FeatureBox.Header>
                <IconWrapper elevation={1} variant="circle">
                  {box.icon}
                </IconWrapper>
              </FeatureBox.Header>
              <FeatureBox.Body>
                <FeatureBox.Title variant="h4">{box.heading}</FeatureBox.Title>
                <FeatureBox.Text>{box.description}</FeatureBox.Text>
              </FeatureBox.Body>
            </FeatureBoxBase>
          ))}
        </ColumnTrack>
      </Section.Contents>
    </SectionBase>
  )
}

const StyledCopyTextHeading = styled(CopyText.Heading)`
  max-width: 684px;
`

export default PlusLiveEvents
