import type { FieldValues } from 'react-hook-form'

import { fetchUserRegisteredByEmail } from '@segment/api/staticSiteBackend/segmentProfilesApi'

export const verifyUserHasAccess = async (data: FieldValues): Promise<boolean> => {
  // If form is sending other data then just email,
  // It means that the form is valid and the form event
  // can be triggered. So user is ready to get access.
  if (data.name || data.companyJobTitle) {
    return Promise.resolve(true)
  }

  try {
    const controller = new AbortController()

    // Fetching data from Profiles API to check if user
    // has any `get access` event associated to it
    const response = await fetchUserRegisteredByEmail(controller.signal, {
      eventName: `Program Signup`,
      formId: `growth-masters-signup`,
      email: data.email,
    })

    return Promise.resolve(Boolean(response.properties.email))
  } catch (_err) {}

  // If user email is not found,
  // it should expand the form and ask for
  // more information.
  return Promise.resolve(false)
}
