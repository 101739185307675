import { type FC, useMemo } from 'react'
import type { GetStaticProps, InferGetStaticPropsType } from 'next'
import { type DehydratedState, dehydrate, QueryClient } from '@tanstack/react-query'

import useInitialCookie from 'views/GrowthMasters/hooks/useInitialCookie'

import { GatedContentProvider } from 'contexts/gatedContent'

import { createLessonsQuery, DEFAULT_LIMIT } from '@segment/api/routes/growthMasters/queries'

import { createStaticSiteBackend, StaticSiteBackendProvider } from '@segment/contexts/staticSiteBackend'

import type { AppExternalPageProps } from '@segment/next-shared/Providers'

import GrowthMastersView from '../../views/GrowthMasters/LandingPage'

const GrowthMasters: FC<InferGetStaticPropsType<typeof getStaticProps>> = () => {
  const staticSiteBackend = useMemo(() => createStaticSiteBackend(), [])
  const [showContent, isLoading, updateRegistered] = useInitialCookie()

  return (
    <StaticSiteBackendProvider value={staticSiteBackend}>
      <GatedContentProvider value={{ showContent, updateRegistered }}>
        {isLoading ? null : <GrowthMastersView />}
      </GatedContentProvider>
    </StaticSiteBackendProvider>
  )
}

export const getStaticProps: GetStaticProps<AppExternalPageProps> = async () => {
  const queryClient = new QueryClient()
  const staticSiteBackend = createStaticSiteBackend()

  await queryClient.prefetchQuery(...createLessonsQuery(staticSiteBackend, DEFAULT_LIMIT), {
    staleTime: Infinity,
  })

  const dangerousDehydratedState = dehydrate(queryClient)

  const safeDehydratedState: DehydratedState = JSON.parse(JSON.stringify(dangerousDehydratedState))

  const props: AppExternalPageProps = {
    dehydratedState: safeDehydratedState,
  }

  return {
    props,
  }
}

export default GrowthMasters
