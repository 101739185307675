import type { FC } from 'react'

import { useGatedContent } from 'contexts/gatedContent'

import { useLessonsQuery } from '@segment/api/routes/growthMasters/queries'

import { PageConfigProvider, usePageConfig } from '@segment/contexts/page'

import Layout from '../components/Layout'

import WantToLearnMore from '../components/WantToLearnMore'

import Hero from './Hero'
import PlusLiveEvents from './PlusLiveEvents'
import SignUp from './SignUp'
import WhatWillYouGet from './WhatWillYouGet'

// GrowthMasters page is hosted on the different domain then the rest of the app
// so we don't want to use an absolute urls for the links
const customPageConfig = {
  baseUrl: ``,
}

const GrowthMasters: FC = () => {
  const defaultPageConfig = usePageConfig()
  const { data } = useLessonsQuery({ args: [6] })
  const { showContent } = useGatedContent()
  const pageConfig = {
    ...defaultPageConfig,
    ...customPageConfig,
  }

  if (!data) throw new Error(`Lessons are missing. Make sure it's preloaded`)

  return (
    <PageConfigProvider value={pageConfig}>
      <Layout pageName="">
        <Hero />
        <WhatWillYouGet lessons={data} />
        {showContent ? (
          <WantToLearnMore />
        ) : (
          <>
            <PlusLiveEvents />
            <SignUp />
          </>
        )}
      </Layout>
    </PageConfigProvider>
  )
}

export default GrowthMasters
