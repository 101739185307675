import styled from '@emotion/styled'
import type { FC } from 'react'

import Button from '@segment/matcha/components/Button'

import SectionBase, * as Section from '@segment/matcha/recipes/Section'
import CopyTextBase, * as CopyText from '@segment/matcha/recipes/CopyText'

import { scrollToAnchor } from '@segment/utils/useScrollToAnchor'

const copy = {
  heading: `Sign up to unlock all the lessons, cheat sheets, and exclusive live events`,
  buttonText: `Get started`,
}

const SignUp: FC = () => {
  return (
    <StyledSectionBase variant="centered">
      <Section.Copy>
        <CopyTextBase centered={true}>
          <CopyText.Heading tag="h3" variant="h2">
            {copy.heading}
          </CopyText.Heading>
        </CopyTextBase>
      </Section.Copy>
      <StyledSectionContents>
        <Button
          onClick={() => scrollToAnchor(`growth-master-hero`, 0, false)}
          color="primary"
          variant="filled"
          size="large"
        >
          {copy.buttonText}
        </Button>
      </StyledSectionContents>
    </StyledSectionBase>
  )
}

const StyledSectionBase = styled(SectionBase)`
  ${Section.Wrapper} {
    gap: 36px;
  }
`

const StyledSectionContents = styled(Section.Contents)`
  text-align: center;
`

export default SignUp
