
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/growth-masters",
      function () {
        return require("private-next-pages/growth-masters/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/growth-masters"])
      });
    }
  