import type { FC } from 'react'
import { useMemo } from 'react'
import { m, MotionConfig } from 'framer-motion'
import styled from '@emotion/styled'

import Image from '@segment/matcha/components/Image'
import Button from '@segment/matcha/components/Button'

import HeroBase, * as Hero from '@segment/matcha/recipes/Hero'
import * as CopyText from '@segment/matcha/recipes/CopyText'

import GetAccessForm from '@segment/templates/Forms/GetAccessForm'
import LogoCarousel from '@segment/templates/LogoCarousel'

import { carouselLogos } from '@segment/templates/LogoCarousel/data'

import { useGatedContent } from 'contexts/gatedContent'

const logos = [
  carouselLogos.EVENTBRITE,
  carouselLogos.SLACK,
  carouselLogos.ANDREESSEN_HOROWITZ,
  carouselLogos.Y_COMBINATOR,
  carouselLogos.SCOOP,
  carouselLogos.IMPERFECT_FOODS,
  carouselLogos.SHOPIFY,
  carouselLogos.HUBSPOT,
  carouselLogos.GITHUB,
]

import { fetchProfileCookie } from '@segment/api/staticSiteBackend/segmentProfilesApi'

import { scrollToAnchor } from '@segment/utils/useScrollToAnchor'

import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl'

import { usePageConfig } from '@segment/contexts/page'

import { verifyUserHasAccess } from '../../helper/verifyUserHasAccess'

import heroFirstPng from './assets/Hero1.png'
import heroFirst2xPng from './assets/Hero1@2x.png'
import heroSecondPng from './assets/Hero2.png'
import heroSecond2xPng from './assets/Hero2@2x.png'

const LandingPageHero: FC = () => {
  const pageConfig = usePageConfig()
  const { showContent, updateRegistered } = useGatedContent()
  const controller = new AbortController()

  const copy = useMemo(() => {
    const copy = {
      overline: `Growth Masters by Twilio Segment`,
      heading: ``,
      description: ``,
      buttonLink: ``,
      logoCarouselHeading: ``,
    }

    if (showContent) {
      copy.heading = `Welcome to Growth Masters for Product Managers`
      copy.description = `The place to sharpen your skills, be inspired by experts, and learn from your peers.`
      copy.buttonLink = `/growth-masters/curriculum/`
    } else {
      copy.heading = `Join Growth Masters for Product Managers`
      copy.description = `Perfect the art of building and scaling products that customers love.`
      copy.logoCarouselHeading = `Featuring speakers from:`
    }

    return copy
  }, [showContent])

  return (
    <Wrapper id="growth-master-hero">
      <StyledHeroBase variant="2Col" copyVariant="narrow">
        <StyledHeroCopy>
          <CopyText.Overline tag="h5" variant="h5">
            {copy.overline}
          </CopyText.Overline>
          <CopyText.Heading tag="h1" variant="h1">
            {copy.heading}
          </CopyText.Heading>
          <CopyText.Description tag="p" variant="pExtraLarge">
            {copy.description}
          </CopyText.Description>
          {showContent ? (
            <Button color="primary" variant="filled" href={getAbsoluteUrl(copy.buttonLink, pageConfig)} size="large">
              Get started
            </Button>
          ) : (
            <StyledGetAccessForm
              onSubmit={(data) => verifyUserHasAccess(data)}
              onSuccess={(data) => {
                fetchProfileCookie(controller.signal, data.email)
                updateRegistered(true)
                scrollToAnchor(`growth-master-hero`, 0, false)
              }}
              customFormIdentifier="growth-masters-signup"
            />
          )}
        </StyledHeroCopy>
        <StyledHeroContents shrinkHeight={showContent}>
          <MotionConfig
            reducedMotion="user"
            transition={{
              duration: 1,
              delay: 3.5,
              repeat: Infinity,
              repeatType: `reverse`,
              repeatDelay: 3.5,
            }}
          >
            <ImageWrapper
              initial={{ opacity: 1 }}
              animate={{
                opacity: 0,
              }}
              shrinkHeight={showContent}
            >
              <picture>
                <source type="image/webp" srcSet={`${heroFirstPng}, ${heroFirst2xPng} 2x`} />
                <Image width={800} height={780} src={heroFirst2xPng} alt="Hero image" />
              </picture>
            </ImageWrapper>
            <ImageWrapper
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              shrinkHeight={showContent}
            >
              <picture>
                <source type="image/webp" srcSet={`${heroSecondPng}, ${heroSecond2xPng} 2x`} />
                <Image width={800} height={780} src={heroSecond2xPng} alt="Hero image" />
              </picture>
            </ImageWrapper>
          </MotionConfig>
        </StyledHeroContents>
      </StyledHeroBase>
      {showContent ? null : <LogoCarousel heading={copy.logoCarouselHeading} logos={logos} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-bottom: 64px;
  background: ${({ theme }) => theme.palette.neutrals.white[100]};
`

interface ShrinkContentsProp {
  shrinkHeight: boolean
}
const StyledHeroBase = styled(HeroBase)`
  ${({ theme }) => theme.breakpoints.up(`xl`)} {
    margin-bottom: 60px;
  }
`

const StyledHeroContents = styled(Hero.Contents)<ShrinkContentsProp>`
  position: relative;
  align-self: flex-start;
  justify-content: center;
  display: flex;
  min-height: 530px;
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    min-height: ${({ shrinkHeight }) => (shrinkHeight ? `530px` : `650px`)};
  }
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    min-height: ${({ shrinkHeight }) => (shrinkHeight ? `300px` : `330px`)};
  }
`

const StyledHeroCopy = styled(Hero.Copy)`
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    padding-top: 80px;
  }
`

const ImageWrapper = styled(m.div)<ShrinkContentsProp>`
  position: absolute;
  top: 40px;
  ${({ theme }) => theme.breakpoints.up(`xl`)} {
    scale: 1.25;
  }
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    top: ${({ shrinkHeight }) => (shrinkHeight ? `80px` : `120px`)};
  }
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    max-width: 315px;
  }
`

const StyledGetAccessForm = styled(GetAccessForm)`
  ${({ theme }) => theme.breakpoints.up(`lg`)} {
    max-width: 417px;
  }
`

export default LandingPageHero
