import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import Link from 'next/link';
import SegmentLogo from '@segment/matcha/components/SegmentLogo';
import Divider from '@segment/matcha/components/Divider';
import useTrustArcLink from '@segment/matcha/recipes/Footer/useTrustArcLink';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl';
import { usePageConfig } from '@segment/contexts/page';
import * as views from './FooterCompact.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createElement as _createElement } from "@emotion/react";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var data = {
  bottomLinks: [{
    url: "https://www.twilio.com/legal/privacy/",
    title: "Privacy"
  }, {
    url: "https://www.twilio.com/legal/tos/",
    title: "Terms"
  }],
  copyright: "\xA9 ".concat(new Date().getUTCFullYear(), " Twilio Inc. All Rights Reserved.")
};

var BaseFooterCompact = function BaseFooterCompact(_ref) {
  var tokens = _ref.tokens,
      _ref$divider = _ref.divider,
      divider = _ref$divider === void 0 ? false : _ref$divider,
      backgroundColor = _ref.backgroundColor;
  var pageConfig = usePageConfig();
  var hasTrustArcEnabled = useTrustArcLink();
  return _jsxs(views.Wrapper, {
    background: backgroundColor || tokens.background,
    children: [divider && _jsx(Divider, {}), _jsxs(views.FooterContent, {
      children: [_jsx(Link, {
        href: getAbsoluteUrl("/", pageConfig),
        children: _jsx(views.BrandLink, {
          children: _jsx(SegmentLogo, {
            appearance: tokens.logoAppearance,
            alt: "Twilio, Inc.",
            footer: true
          })
        })
      }), _jsxs(views.MenuLinks, {
        children: [_jsx(views.MenuItem, _objectSpread(_objectSpread({}, tokens.menuItem), {}, {
          children: data.copyright
        })), data.bottomLinks.map(function (_ref2) {
          var title = _ref2.title,
              url = _ref2.url;
          return _createElement(views.MenuItem, _objectSpread(_objectSpread({}, tokens.menuItem), {}, {
            key: title
          }), _jsx(views.DividerLine, {
            children: "|"
          }), _jsx(views.MenuLink, _objectSpread(_objectSpread({}, tokens.menuLink), {}, {
            href: url,
            children: title
          })));
        }), hasTrustArcEnabled && _jsxs(views.MenuItem, _objectSpread(_objectSpread({}, tokens.menuItem), {}, {
          children: [_jsx(views.DividerLine, {
            children: "|"
          }), _jsx(views.MenuLink, _objectSpread(_objectSpread({}, tokens.menuLink), {}, {
            id: "teconsent"
          }))]
        }))]
      })]
    })]
  });
};

export var FooterCompact = withDesignTokens(BaseFooterCompact, function () {
  return {};
}, {
  dark: function dark(theme) {
    return {
      background: theme.palette.twilio.gray[10],
      menuItem: {
        color: theme.palette.twilio.gray[100]
      },
      menuLink: {
        color: theme.palette.twilio.gray[100]
      },
      logoAppearance: "light"
    };
  },
  light: function light(theme) {
    return {
      background: theme.palette.twilio.gray[10],
      menuItem: {
        color: theme.palette.twilio.gray[100]
      },
      menuLink: {
        color: theme.palette.twilio.gray[100]
      },
      logoAppearance: "light"
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      background: theme.palette.twilio.gray[10],
      menuItem: {
        color: theme.palette.twilio.gray[100]
      },
      menuLink: {
        color: theme.palette.twilio.gray[100]
      },
      logoAppearance: "light"
    };
  },
  twilioDark: function twilioDark(theme) {
    return {
      background: theme.palette.twilio.gray[100],
      menuItem: {
        color: theme.palette.neutrals.white[100]
      },
      menuLink: {
        color: theme.palette.neutrals.white[100]
      },
      logoAppearance: "twilioDark"
    };
  },
  cdpWeek2023: function cdpWeek2023(theme) {
    return {
      background: theme.palette.twilio.gray[10],
      menuItem: {
        color: theme.palette.twilio.gray[100]
      },
      menuLink: {
        color: theme.palette.twilio.gray[100]
      },
      logoAppearance: "light"
    };
  }
});